import Alpine from 'alpinejs'
import Axios from 'axios'
import collapse from '@alpinejs/collapse'
import masonry from 'alpinejs-masonry';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

Alpine.plugin(collapse)
Alpine.plugin(masonry)

window.Alpine = Alpine
window.Axios = Axios
window.tippy = tippy

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content;

window.addEventListener('DOMContentLoaded', () => Alpine.start())
